import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Section from '../sections/section';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulLayoutMain(slug: { eq: "/404" }) {
        id
        title
        node_locale
        description
        contentful_id
        menu {
          name
          type
          menuItems {
            id
            title
            url
          }
        }
        contentModule {
          ... on Node {
            id
            __typename
          }
        }
      }
    }
  `);

  const {
    title,
    node_locale,
    description,
    menu,
    contentModule,
  } = data.contentfulLayoutMain;

  return (
    <Layout menus={menu} nodeLocale={node_locale}>
      <SEO title={title} description={description} />
      {contentModule
        && contentModule.length > 0
        && contentModule.map((content) => (
          <Section
            contentModuleId={content.id}
            type={content.__typename}
            key={content.id}
          />
        ))}
    </Layout>
  );
};

export default NotFoundPage;
